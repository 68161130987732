import { InfoNumberEnum } from "@/core/enums/info_number";
import { AssessmentEmailType } from "@/core/types/assessments";
import http from "@/services/http";
import { QueryType } from "@/services/api/clients-api";
import { QuestionnaireType } from "@/core/types/questionnaires";
import { GrantLicense } from "@/modules/evaluator/models/clients/clients";
import { SupplierProfileType } from "@/core/profile-type";
import { PaginatedResponse } from "@/core/models/paginated-response.type";
import { EvaluatorClientsListItem } from "@/modules/evaluator/models/clients/evaluator-clients-list-item.type";
import { EvaluatorClientCreated } from "@/modules/evaluator/models/clients/evaluator-client-created.type";
import { EvaluatorClientShort } from "@/modules/evaluator/models/clients/evaluator-client-short.type";
import { EvaluatorClientEditDto } from "@/modules/evaluator/models/clients/evaluator-client-edit.dto";
import { EvaluatorClientCreateDto } from "@/modules/evaluator/models/clients/evaluator-client-create.dto";
import { EvaluatorAssessmentsListItem } from "@/modules/evaluator/models/assessments/evaluator-assessments-list-item.type";
import { SubscriptionTierCardType } from '@/core/types/subscription';

const resource = `${process.env.VUE_APP_API_URL}evaluators/`;

const moveToEvaluation = (id: number) =>
  http.post(`${resource}assessments/${id}/move_to_evaluation/`, {});

const fetchEvaluatorsAssessments = (
  query: QueryType
): Promise<PaginatedResponse<EvaluatorAssessmentsListItem>> =>
  http.get(`${resource}assessments/`, { params: query });
const fetchEvaluatorsAssessmentsOverview = (query: QueryType) =>
  http.get(`${resource}assessments/overview/`, { params: query });
const fetchEvaluatorsClients = (
  query: QueryType
): Promise<PaginatedResponse<EvaluatorClientsListItem>> =>
  http.get(`${resource}clients/`, { params: query });
const fetchEvaluatorsClient = (id: number): Promise<EvaluatorClientsListItem> =>
  http.get(`${resource}clients/${id}/`);
const deleteEvaluatorsClient = (id: number) =>
  http.delete(`${resource}clients/${id}/`);
const fetchSubscriptionPlans = () =>
  http.get(`${resource}subscription_plan/`);
const fetchEvaluatorsAssessmentById = (id: number) =>
  http.get(`${resource}assessments/${id}/`);
const fetchEvaluatorsAssessmentQuestions = (query: QueryType) =>
  http.get(`${resource}assessment_questions/`, { params: query });
const deleteQuestion = (id: number, assessment_id: number) =>
  http.delete(`${resource}assessment_questions/${id}/`, {
    params: { assessment_id },
  });
const editQuestion = (
  id: number,
  assessment_id: number,
  data: {
    [key: string]: string;
  }
) =>
  http.put(`${resource}assessment_questions/${id}/`, data, {
    params: { assessment_id },
  });

const supplierResendInvite = (id: number) =>
  http.post(`${resource}suppliers/${id}/resend_invite/`, {});

const createClient = (
  formData: EvaluatorClientCreateDto
): Promise<EvaluatorClientCreated> =>
  http.post(`${resource}clients/`, formData);
const editClient = (
  formData: EvaluatorClientEditDto,
  id: number
): Promise<EvaluatorClientShort> =>
  http.patch(`${resource}clients/${id}/`, formData);
const grantLicense = (
  formData: GrantLicense,
  id: number
): Promise<GrantLicense> =>
  http.post(`${resource}clients/${id}/grant_licenses/`, formData);
const clientResendInvite = (id: number) =>
  http.post(`${resource}clients/${id}/resend_invite/`, {});

const getPlans = (params?: string | null): Promise<SubscriptionTierCardType[]> => {
  return http.get(
    `${process.env.VUE_APP_API_URL}subscriptions/plans${params ?? '?with_trials=true'}`
  );
};

const fetchClientQuestionnaires = (id: number, query?: Record<string, any>): Promise<SubscriptionTierCardType[]> => {
  return http.get(
    `${process.env.VUE_APP_API_URL}evaluators/clients/${id}/questionnaires/`,
    { params: query }
  );
};
const questionnaireProApprove = (id: number) =>
  http.post(`${process.env.VUE_APP_API_URL}questionnaires/questionnaires/${id}/approve/`, {});
const questionnaireProDecline = (id: number) =>
  http.post(`${process.env.VUE_APP_API_URL}questionnaires/questionnaires/${id}/decline/`, {});

const setSubscriptionPlan = (id: string, data: {[key: string]: any}) =>
  http.post(`${resource}clients/${id}/set_enterprise_plan/`, data);
const prolongateSubscription = (id: string, data: {[key: string]: any}) =>
  http.post(`${resource}clients/${id}/prolongate_subscription/`, data);
const cancelSubscription = (id: string, data: {[key: string]: any}) =>
  http.post(`${resource}clients/${id}/cancel_subscription/`, data);

const fetchEvaluations = (id: string) =>
  http.get(`${resource}clients/${id}/available_licenses/`);
const addLicenses = (id: string, data: {[key: string]: any}) =>
  http.post(`${resource}clients/${id}/add_licenses/`, data);
const deleteEvaluation = (id: string, data: {[key: string]: any}) =>
  http.post(`${resource}clients/${id}/delete_licenses/`, data);
const updateLicense = (id: string, data: {[key: string]: any}) =>
  http.post(`${resource}clients/${id}/update_licenses/`, data);

const assignAssessmentToMe = (id: number) =>
  http.post(`${resource}assessments/${id}/assign_to_me/`, {});
const completeAssessment = (id: number) =>
  http.post(`${resource}assessments/${id}/complete/`, {});
const returnAssessmentToSupplier = (id: number, data: AssessmentEmailType) =>
  http.post(`${resource}assessments/${id}/return_to_supplier/`, data);
const setAssessmentRating = (id: number, rating: InfoNumberEnum) =>
  http.post(`${resource}assessments/${id}/set_rating/`, { rating });

const fetchDashboard = () => http.get(`${resource}dashboard/`);

const fetchQuestionnaires = (query?: QueryType) =>
  http.get(`${resource}questionnaires/`, { params: query });
const fetchQuestionnaireById = (id: number): Promise<QuestionnaireType> =>
  http.get(`${resource}questionnaires/${id}/`);
const editQuestionnaire = (id: number, data: any) =>
  http.put(`${resource}questionnaires/${id}/`, data);
const createQuestionnaire = (data: any) =>
  http.post(`${resource}questionnaires/`, data);
const uploadQuestionnaireCsv = (id: number, data: any) =>
  http.post(`${resource}questionnaires/${id}/upload/`, data);
const uploadClientsCsv = (id: number, data: any) =>
  http.post(`${resource}clients/${id}/import_suppliers/`, data);
const deleteQuestionnaire = (id: number) =>
  http.delete(`${resource}questionnaires/${id}/`);

// PROFILE
const fetchProfile = (): Promise<SupplierProfileType> =>
  http.get(`${resource}profile/get/`);
const updateProfile = (data: SupplierProfileType) =>
  http.post(`${resource}profile/edit/`, data);
//

export default {
  moveToEvaluation,
  fetchEvaluatorsAssessments,
  fetchEvaluatorsAssessmentById,
  fetchEvaluatorsAssessmentQuestions,
  fetchEvaluatorsClients,
  fetchEvaluatorsClient,
  deleteEvaluatorsClient,
  fetchSubscriptionPlans,
  fetchEvaluatorsAssessmentsOverview,
  assignAssessmentToMe,
  completeAssessment,
  returnAssessmentToSupplier,
  createClient,
  editClient,
  deleteQuestion,
  editQuestion,
  fetchQuestionnaires,
  fetchQuestionnaireById,
  editQuestionnaire,
  uploadQuestionnaireCsv,
  uploadClientsCsv,
  createQuestionnaire,
  grantLicense,
  setAssessmentRating,
  clientResendInvite,
  fetchProfile,
  updateProfile,
  fetchDashboard,
  deleteQuestionnaire,
  supplierResendInvite,
  fetchEvaluations,
  addLicenses,
  deleteEvaluation,
  updateLicense,
  getPlans,
  setSubscriptionPlan,
  prolongateSubscription,
  cancelSubscription,
  fetchClientQuestionnaires,
  questionnaireProApprove,
  questionnaireProDecline
};
